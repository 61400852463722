// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Elasticsearch2 from "../../blocks/elasticsearch2/src/Elasticsearch2";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Bhimupiintegration from "../../blocks/bhimupiintegration/src/Bhimupiintegration";
import Inventorymanagement3 from "../../blocks/inventorymanagement3/src/Inventorymanagement3";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Location from "../../blocks/location/src/Location";
import Onlinebookingintegration from "../../blocks/onlinebookingintegration/src/Onlinebookingintegration";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Sorting from "../../blocks/sorting/src/Sorting";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Chat from "../../blocks/chat/src/Chat";
import ChatView from "../../blocks/chat/src/ChatView";
import Couponcodegenerator from "../../blocks/couponcodegenerator/src/Couponcodegenerator";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Crm3rdpartyintegration from "../../blocks/crm3rdpartyintegration/src/Crm3rdpartyintegration";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Maps from "../../blocks/maps/src/Maps";



const routeMap = {
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
Elasticsearch2:{
 component:Elasticsearch2,
path:"/Elasticsearch2"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Bhimupiintegration:{
 component:Bhimupiintegration,
path:"/Bhimupiintegration"},
Inventorymanagement3:{
 component:Inventorymanagement3,
path:"/Inventorymanagement3"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Location:{
 component:Location,
path:"/Location"},
Onlinebookingintegration:{
 component:Onlinebookingintegration,
path:"/Onlinebookingintegration"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Chat:{
 component:Chat,
path:"/Chat"},
ChatView:{
 component:ChatView,
path:"/ChatView"},
Couponcodegenerator:{
 component:Couponcodegenerator,
path:"/Couponcodegenerator"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Crm3rdpartyintegration:{
 component:Crm3rdpartyintegration,
path:"/Crm3rdpartyintegration"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Maps:{
 component:Maps,
path:"/Maps"},

  Home: {
component:ApiIntegration,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
